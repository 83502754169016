.doctors-information-container{
    @apply flex h-fit items-center w-full
}

.di-head-row{
    @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase 
}

.di-table-container{
    @apply flex flex-col w-full mt-3
}
