.dp-header{
    @apply flex w-full items-center gap-5 md:gap-0
}

.dp-header-search-container{
    @apply flex w-full h-full justify-center items-center
}

.dp-info-container{
    @apply flex w-full p-3  flex-col 
}

.dp-info-header{
    @apply flex w-full justify-between items-center h-fit
}

.doctor-add-button{
    @apply bg-secondary text-white   text-sm rounded-md  gap-2 p-2 active:bg-secondary items-center
}